body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hiddenMenu{
    display: none;
    visibility: none;
    width: 0;
    height: 0;
    margin:0;
    padding:0;
}

.visibleMenu{
    display: block;
    visibility:visible;
    float:left;
    width:25%;
    height: 100vh;
}

.visibleMenu button{
    border:none;
    border-radius: none;
    width: 100%;
    background-color: #565752;
    color:white;
    font-size: 20px;
    margin: 5px;
    padding:5px;
}
.visibleMenu button:hover{
    padding:10px;
    margin:0;
    cursor: pointer;
    font-weight: bold;
}

html{
    background-color: #4b5320;
}

main{
    height: 80vh;
}
.signin-block{
    padding:0;
    margin:5px auto;
    max-width: 100%;
}

.header-img{
    display: block;
    padding:0;
    margin:5px auto;
    max-width: 100%;
}

.signin-block input{
    display: block;
    margin:5px auto;
    height: 20px;
    padding:5px;
    border-radius: 5px;
    font-size: 20px;
    max-width: calc(100% - 10px);
}
.signin-block textarea{
    display: block;
    margin:5px auto;
    padding:5px;
    border-radius: 5px;
    font-size: 20px;
    width:50%;
    height: 20%;
}
.signin-block h1{
    text-align: center;
}

.signin-block button{
    display: block;
    padding:5px;
    border-radius: 5px;
    font-size: 20px;
    margin:5px auto;
    width:226px;
    max-width: calc(100% - 10px);
}

.signin-block label{
    display: block;
    text-align: center;
}

footer{
    color:white;
    text-align: center;
}

nav{
    display:block;
    width: 100%;
    height: 50px;
}

nav li{
    list-style-type: none;
}

nav a{
    color: white;
}

/* nav a:hover{
    color: white;
    text-decoration: none;
    font-weight: bold;
} */

nav a:active{
    color:white;
}


.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    font-size: 16px;
    opacity: 65%;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


  .hiddenNav{
    display: none;
    visibility: hidden;
  }

  .showNav{
      display: block;
      visibility: visible;
  }

  .projectcard{
      width:calc(25% - 38px);
      float: left;
      padding:10px;
      margin:5px;
      border:4px solid white;
      border-radius: 4px;
  }

  .projectcard:hover{
      cursor: pointer;
      border-color: white;
      border-radius: 4px;
      border:4px solid grey;
  }